import React, { useEffect, useState } from 'react'
import { navigate } from '@reach/router';
import Layout from "../share/layout"
import Image from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import '../../styles/template2.css'
import '../../styles/template2responsive.css'
import Modal from 'react-bootstrap/Modal'
import AudioPlayer from 'react-h5-audio-player';
import Arrow_Up from '../../images/template2-up.png'
import Arrow_Down from '../../images/template2-down.png'
import { useLocation } from "@reach/router"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import ShareButton from '../share/ShareButton';

const TemplateChabucaTemplate = ({ pageContext }) => {
  const { pathname } = useLocation();

  const [NameLinksModal1, setNameLinksModal1] = useState()
  const [LinksModal1, setLinksModal1] = useState()


  const [showA1, setShowA1] = useState(false);
  const [showA2, setShowA2] = useState(false);
  const [showA3, setShowA3] = useState(false);
  const [showA4, setShowA4] = useState(false);
  const [showA5, setShowA5] = useState(false);
  const [showA6, setShowA6] = useState(false);
  const [showA7, setShowA7] = useState(false);
  const [showA8, setShowA8] = useState(false);
  const [showA9, setShowA9] = useState(false);
  const [showA10, setShowA10] = useState(false);
  const [showB1, setShowB1] = useState(false);
  const [showB2, setShowB2] = useState(false);
  const [showB3, setShowB3] = useState(false);
  const [showB4, setShowB4] = useState(false);
  const [showB5, setShowB5] = useState(false);
  const [showB6, setShowB6] = useState(false);
  const [showB7, setShowB7] = useState(false);
  const [showB8, setShowB8] = useState(false);
  const [showB9, setShowB9] = useState(false);
  const [showB10, setShowB10] = useState(false);
  const [showC1, setShowC1] = useState(false);
  const [showC2, setShowC2] = useState(false);
  const handleCloseA1 = () => setShowA1(false);
  const handleCloseA2 = () => setShowA2(false);
  const handleCloseA3 = () => setShowA3(false);
  const handleCloseA4 = () => setShowA4(false);
  const handleCloseA5 = () => setShowA5(false);
  const handleCloseA6 = () => setShowA6(false);
  const handleCloseA7 = () => setShowA7(false);
  const handleCloseA8 = () => setShowA8(false);
  const handleCloseA9 = () => setShowA9(false);
  const handleCloseA10 = () => setShowA10(false);
  const handleCloseB1 = () => setShowB1(false);
  const handleCloseB2 = () => setShowB2(false);
  const handleCloseB3 = () => setShowB3(false);
  const handleCloseB4 = () => setShowB4(false);
  const handleCloseB5 = () => setShowB5(false);
  const handleCloseB6 = () => setShowB6(false);
  const handleCloseB7 = () => setShowB7(false);
  const handleCloseB8 = () => setShowB8(false);
  const handleCloseB9 = () => setShowB9(false);
  const handleCloseB10 = () => setShowB10(false);
  const handleCloseC1 = () => setShowC1(false);
  const handleCloseC2 = () => setShowC2(false);

  const handleShowA1 = () => setShowA1(true);
  const handleShowA2 = () => setShowA2(true);
  const handleShowA3 = () => setShowA3(true);
  const handleShowA4 = () => setShowA4(true);
  const handleShowA5 = () => setShowA5(true);
  const handleShowA6 = () => setShowA6(true);
  const handleShowA7 = () => setShowA7(true);
  const handleShowA8 = () => setShowA8(true);
  const handleShowA9 = () => setShowA7(true);
  const handleShowA10 = () => setShowA8(true);
  const handleShowB1 = () => setShowB1(true);
  const handleShowB2 = () => setShowB2(true);
  const handleShowB3 = () => setShowB3(true);
  const handleShowB4 = () => setShowB4(true);
  const handleShowB5 = () => setShowB5(true);
  const handleShowB6 = () => setShowB6(true);
  const handleShowB7 = () => setShowB7(true);
  const handleShowB8 = () => setShowB8(true);
  const handleShowB9 = () => setShowB9(true);
  const handleShowB10 = () => setShowB10(true);
  const handleShowC1 = () => setShowC1(true);
  const handleShowC2 = () => setShowC2(true);


  const [DisplayClassModal1, setDisplayClassModal1] = useState(false)
  const [DisplayClassModal2, setDisplayClassModal2] = useState(false)
  const [DisplayClassModal3, setDisplayClassModal3] = useState(false)
  const [DisplayClassModal4, setDisplayClassModal4] = useState(false)
  const [DisplayClassModal5, setDisplayClassModal5] = useState(false)
  const [DisplayClassModal6, setDisplayClassModal6] = useState(false)
  const [DisplayClassModal7, setDisplayClassModal7] = useState(false)
  const [DisplayClassModal8, setDisplayClassModal8] = useState(false)
  const [DisplayClassModal9, setDisplayClassModal9] = useState(false)
  const [DisplayClassModal10, setDisplayClassModal10] = useState(false)


  const [ImageModal1, setImageModal1] = useState()
  const [ImageModal2, setImageModal2] = useState()
  const [ImageModal3, setImageModal3] = useState()
  const [ImageModal4, setImageModal4] = useState()
  const [ImageModal5, setImageModal5] = useState()
  const [ImageModal6, setImageModal6] = useState()
  const [ImageModal7, setImageModal7] = useState()
  const [ImageModal8, setImageModal8] = useState()
  const [ImageModal9, setImageModal9] = useState()
  const [ImageModal10, setImageModal10] = useState()


  const {
    metaTitle, metaKeywords, metaDescripcin,
    iconCloseModal, seccionAmodalDivided, OrnamentSectionB, seccionAmodal10, seccionAmodal9, seccionAmodal8, seccionAmodal7, seccionAmodal6, seccionAmodal5, seccionAmodal4, seccionAmodal3, seccionAmodal2, seccionAmodal1, backgroundsImage, imagendelcarrusel, tItulo, subtitulo, seccionCmodal2Part3Links, seccionCmodal2Part3, seccionCmodal2Part2, seccionCmodal2Part1, seccionCmodal1Text, seccionCmodal1Links, seccionCmodal1, ButtonsASectionC, ButtonsBSectionC, seccionBmodal1, seccionBmodal2, seccionBmodal3, seccionBmodal4, seccionBmodal5, seccionBmodal6, seccionBmodal7, seccionBmodal8, seccionBmodal9, seccionBmodal10, sectionBtitle, ButtonsASectionB, ButtonsBSectionB, imagen1, imagen2, imagen3, titlesectionA, ButtonsASectionA, ButtonsBSectionA, OrnamentSectionA } = pageContext.data

  useEffect(() => {
    let Links = seccionCmodal1Links.seccionCmodal1Links.split('"')
    let NameLinksSplit = [];
    let LinksSplit = [];
    for (let i = 0; i < Links.length; i++) {
      if ((Links[i].includes('-$')) && (Links[i].length > 5)) {
        const temp = Links[i].split('-$');
        NameLinksSplit.push(temp[0]);
        LinksSplit.push(temp[1])
      }
    }
    setNameLinksModal1(NameLinksSplit)
    setLinksModal1(LinksSplit)
  }, [seccionCmodal1Links])

  const size = useWindowSize();

  const optionsModal1 = {
    renderNode: {
      [BLOCKS.HR]: (node, children) => {
        if (DisplayClassModal1 === false) {
          return setDisplayClassModal1(true)
        } else if (DisplayClassModal1 === true) {
          return false
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        if (node.data.target.fields.title["en-US"].includes('diseño') || node.data.target.fields.title["en-US"].includes('diseño2')) {
          if (ImageModal1 === undefined) {
            setImageModal1(node.data.target)
          }
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        } else if (node.data.target.fields.file["en-US"].contentType === "audio/mpeg") {
          return (
            <div className="audio_container_2">
              <AudioPlayer showSkipControls={false}
                autoPlay
                src={node.data.target.fields.file["en-US"].url}
                style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
              />
            </div>
          )
        } else {
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        }
      },
      [INLINES.HYPERLINK]: (node) => {
        if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && ((node.data.uri).includes("<a")))) {
          const urlVimeo = node.data.uri.split('iframe')
          let urlVimeoEmbed;
          for (let i = 0; i < urlVimeo.length; i++) {
            if ((urlVimeo[i]).includes("src=")) {
              urlVimeoEmbed = urlVimeo[i]
            }
          }
          function createMarkup() { return { __html: `<iframe ${urlVimeoEmbed} iframe>` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && !((node.data.uri).includes("<a")))) {
          return null
        } else if ((node.data.uri).includes("<iframe") && !(node.data.uri).includes("vimeo")) {
          function createMarkup() { return { __html: `${node.data.uri}` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else {
          return <a href={node.data.uri} style={{ 'color': 'red' }} target="_blank" rel="noreferrer"><b>{node.content[0].value}</b></a>
        }
      },
    }
  }

  const optionsModal2 = {
    renderNode: {
      [BLOCKS.HR]: (node, children) => {
        if (DisplayClassModal2 === false) {
          return setDisplayClassModal2(true)
        } else if (DisplayClassModal2 === true) {
          return false
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        if (node.data.target.fields.title["en-US"].includes('diseño') || node.data.target.fields.title["en-US"].includes('diseño2')) {
          if (ImageModal2 === undefined) {
            setImageModal2(node.data.target)
          }
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        } else if (node.data.target.fields.file["en-US"].contentType === "audio/mpeg") {
          return (
            <div className="audio_container_2">
              <AudioPlayer showSkipControls={false}
                autoPlay
                src={node.data.target.fields.file["en-US"].url}
                style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
              />
            </div>
          )
        } else {
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        }
      },
      [INLINES.HYPERLINK]: (node) => {
        if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && ((node.data.uri).includes("<a")))) {
          const urlVimeo = node.data.uri.split('iframe')
          let urlVimeoEmbed;
          for (let i = 0; i < urlVimeo.length; i++) {
            if ((urlVimeo[i]).includes("src=")) {
              urlVimeoEmbed = urlVimeo[i]
            }
          }
          function createMarkup() { return { __html: `<iframe ${urlVimeoEmbed} iframe>` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && !((node.data.uri).includes("<a")))) {
          return null
        } else if ((node.data.uri).includes("<iframe") && !(node.data.uri).includes("vimeo")) {
          function createMarkup() { return { __html: `${node.data.uri}` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else {
          return <a href={node.data.uri} style={{ 'color': 'red' }} target="_blank" rel="noreferrer"><b>{node.content[0].value}</b></a>
        }
      },
    }
  }

  const optionsModal3 = {
    renderNode: {
      [BLOCKS.HR]: (node, children) => {
        if (DisplayClassModal3 === false) {
          return setDisplayClassModal3(true)
        } else if (DisplayClassModal3 === true) {
          return false
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        if (node.data.target.fields.title["en-US"].includes('diseño') || node.data.target.fields.title["en-US"].includes('diseño2')) {
          if (ImageModal3 === undefined) {
            setImageModal3(node.data.target)
          }
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        } else if (node.data.target.fields.file["en-US"].contentType === "audio/mpeg") {
          return (
            <div className="audio_container_2">
              <AudioPlayer showSkipControls={false}
                autoPlay
                src={node.data.target.fields.file["en-US"].url}
                style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
              />
            </div>
          )
        } else {
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        }
      },
      [INLINES.HYPERLINK]: (node) => {
        if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && ((node.data.uri).includes("<a")))) {
          const urlVimeo = node.data.uri.split('iframe')
          let urlVimeoEmbed;
          for (let i = 0; i < urlVimeo.length; i++) {
            if ((urlVimeo[i]).includes("src=")) {
              urlVimeoEmbed = urlVimeo[i]
            }
          }
          function createMarkup() { return { __html: `<iframe ${urlVimeoEmbed} iframe>` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && !((node.data.uri).includes("<a")))) {
          return null
        } else if ((node.data.uri).includes("<iframe") && !(node.data.uri).includes("vimeo")) {
          function createMarkup() { return { __html: `${node.data.uri}` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else {
          return <a href={node.data.uri} style={{ 'color': 'red' }} target="_blank" rel="noreferrer"><b>{node.content[0].value}</b></a>
        }
      },
    }
  }

  const optionsModal4 = {
    renderNode: {
      [BLOCKS.HR]: (node, children) => {
        if (DisplayClassModal4 === false) {
          return setDisplayClassModal4(true)
        } else if (DisplayClassModal4 === true) {
          return false
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        if (node.data.target.fields.title["en-US"].includes('diseño') || node.data.target.fields.title["en-US"].includes('diseño2')) {
          if (ImageModal4 === undefined) {
            setImageModal4(node.data.target)
          }
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        } else if (node.data.target.fields.file["en-US"].contentType === "audio/mpeg") {
          return (
            <div className="audio_container_2">
              <AudioPlayer showSkipControls={false}
                autoPlay
                src={node.data.target.fields.file["en-US"].url}
                style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
              />
            </div>
          )
        } else {
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        }
      },
      [INLINES.HYPERLINK]: (node) => {
        if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && ((node.data.uri).includes("<a")))) {
          const urlVimeo = node.data.uri.split('iframe')
          let urlVimeoEmbed;
          for (let i = 0; i < urlVimeo.length; i++) {
            if ((urlVimeo[i]).includes("src=")) {
              urlVimeoEmbed = urlVimeo[i]
            }
          }
          function createMarkup() { return { __html: `<iframe ${urlVimeoEmbed} iframe>` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && !((node.data.uri).includes("<a")))) {
          return null
        } else if ((node.data.uri).includes("<iframe") && !(node.data.uri).includes("vimeo")) {
          function createMarkup() { return { __html: `${node.data.uri}` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else {
          return <a href={node.data.uri} style={{ 'color': 'red' }} target="_blank" rel="noreferrer"><b>{node.content[0].value}</b></a>
        }
      },
    }
  }


  const optionsModal5 = {
    renderNode: {
      [BLOCKS.HR]: (node, children) => {
        if (DisplayClassModal5 === false) {
          return setDisplayClassModal5(true)
        } else if (DisplayClassModal5 === true) {
          return false
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        if (node.data.target.fields.title["en-US"].includes('diseño') || node.data.target.fields.title["en-US"].includes('diseño2')) {
          if (ImageModal5 === undefined) {
            setImageModal5(node.data.target)
          }
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        } else if (node.data.target.fields.file["en-US"].contentType === "audio/mpeg") {
          return (
            <div className="audio_container_2">
              <AudioPlayer showSkipControls={false}
                autoPlay
                src={node.data.target.fields.file["en-US"].url}
                style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
              />
            </div>
          )
        } else {
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        }
      },
      [INLINES.HYPERLINK]: (node) => {
        if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && ((node.data.uri).includes("<a")))) {
          const urlVimeo = node.data.uri.split('iframe')
          let urlVimeoEmbed;
          for (let i = 0; i < urlVimeo.length; i++) {
            if ((urlVimeo[i]).includes("src=")) {
              urlVimeoEmbed = urlVimeo[i]
            }
          }
          function createMarkup() { return { __html: `<iframe ${urlVimeoEmbed} iframe>` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && !((node.data.uri).includes("<a")))) {
          return null
        } else if ((node.data.uri).includes("<iframe") && !(node.data.uri).includes("vimeo")) {
          function createMarkup() { return { __html: `${node.data.uri}` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else {
          return <a href={node.data.uri} style={{ 'color': 'red' }} target="_blank" rel="noreferrer"><b>{node.content[0].value}</b></a>
        }
      },
    }
  }



  const optionsModal6 = {
    renderNode: {
      [BLOCKS.HR]: (node, children) => {
        if (DisplayClassModal6 === false) {
          return setDisplayClassModal6(true)
        } else if (DisplayClassModal6 === true) {
          return false
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        if (node.data.target.fields.title["en-US"].includes('diseño') || node.data.target.fields.title["en-US"].includes('diseño2')) {
          if (ImageModal6 === undefined) {
            setImageModal6(node.data.target)
          }
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        } else if (node.data.target.fields.file["en-US"].contentType === "audio/mpeg") {
          return (
            <div className="audio_container_2">
              <AudioPlayer showSkipControls={false}
                autoPlay
                src={node.data.target.fields.file["en-US"].url}
                style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
              />
            </div>
          )
        } else {
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        }
      },
      [INLINES.HYPERLINK]: (node) => {
        if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && ((node.data.uri).includes("<a")))) {
          const urlVimeo = node.data.uri.split('iframe')
          let urlVimeoEmbed;
          for (let i = 0; i < urlVimeo.length; i++) {
            if ((urlVimeo[i]).includes("src=")) {
              urlVimeoEmbed = urlVimeo[i]
            }
          }
          function createMarkup() { return { __html: `<iframe ${urlVimeoEmbed} iframe>` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && !((node.data.uri).includes("<a")))) {
          return null
        } else if ((node.data.uri).includes("<iframe") && !(node.data.uri).includes("vimeo")) {
          function createMarkup() { return { __html: `${node.data.uri}` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else {
          return <a href={node.data.uri} style={{ 'color': 'red' }} target="_blank" rel="noreferrer"><b>{node.content[0].value}</b></a>
        }
      },
    }
  }


  const optionsModal7 = {
    renderNode: {
      [BLOCKS.HR]: (node, children) => {
        if (DisplayClassModal7 === false) {
          return setDisplayClassModal7(true)
        } else if (DisplayClassModal7 === true) {
          return false
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        if (node.data.target.fields.title["en-US"].includes('diseño') || node.data.target.fields.title["en-US"].includes('diseño2')) {
          if (ImageModal7 === undefined) {
            setImageModal7(node.data.target)
          }
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        } else if (node.data.target.fields.file["en-US"].contentType === "audio/mpeg") {
          return (
            <div className="audio_container_2">
              <AudioPlayer showSkipControls={false}
                autoPlay
                src={node.data.target.fields.file["en-US"].url}
                style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
              />
            </div>
          )
        } else {
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        }
      },
      [INLINES.HYPERLINK]: (node) => {
        if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && ((node.data.uri).includes("<a")))) {
          const urlVimeo = node.data.uri.split('iframe')
          let urlVimeoEmbed;
          for (let i = 0; i < urlVimeo.length; i++) {
            if ((urlVimeo[i]).includes("src=")) {
              urlVimeoEmbed = urlVimeo[i]
            }
          }
          function createMarkup() { return { __html: `<iframe ${urlVimeoEmbed} iframe>` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && !((node.data.uri).includes("<a")))) {
          return null
        } else if ((node.data.uri).includes("<iframe") && !(node.data.uri).includes("vimeo")) {
          function createMarkup() { return { __html: `${node.data.uri}` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else {
          return <a href={node.data.uri} style={{ 'color': 'red' }} target="_blank" rel="noreferrer"><b>{node.content[0].value}</b></a>
        }
      }
    }
  }

  const optionsModal8 = {
    renderNode: {
      [BLOCKS.HR]: (node, children) => {
        if (DisplayClassModal8 === false) {
          return setDisplayClassModal8(true)
        } else if (DisplayClassModal8 === true) {
          return false
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        if (node.data.target.fields.title["en-US"].includes('diseño') || node.data.target.fields.title["en-US"].includes('diseño2')) {
          if (ImageModal8 === undefined) {
            setImageModal8(node.data.target)
          }
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        } else if (node.data.target.fields.file["en-US"].contentType === "audio/mpeg") {
          return (
            <div className="audio_container_2">
              <AudioPlayer showSkipControls={false}
                autoPlay
                src={node.data.target.fields.file["en-US"].url}
                style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
              />
            </div>
          )
        } else {
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        }
      },
      [INLINES.HYPERLINK]: (node) => {
        if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && ((node.data.uri).includes("<a")))) {
          const urlVimeo = node.data.uri.split('iframe')
          let urlVimeoEmbed;
          for (let i = 0; i < urlVimeo.length; i++) {
            if ((urlVimeo[i]).includes("src=")) {
              urlVimeoEmbed = urlVimeo[i]
            }
          }
          function createMarkup() { return { __html: `<iframe ${urlVimeoEmbed} iframe>` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && !((node.data.uri).includes("<a")))) {
          return null
        } else if ((node.data.uri).includes("<iframe") && !(node.data.uri).includes("vimeo")) {
          function createMarkup() { return { __html: `${node.data.uri}` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else {
          return <a href={node.data.uri} style={{ 'color': 'red' }} target="_blank" rel="noreferrer"><b>{node.content[0].value}</b></a>
        }
      },
    }
  }

  const optionsModal9 = {
    renderNode: {
      [BLOCKS.HR]: (node, children) => {
        if (DisplayClassModal9 === false) {
          return setDisplayClassModal9(true)
        } else if (DisplayClassModal9 === true) {
          return false
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        if (node.data.target.fields.title["en-US"].includes('diseño') || node.data.target.fields.title["en-US"].includes('diseño2')) {
          if (ImageModal9 === undefined) {
            setImageModal9(node.data.target)
          }
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        } else if (node.data.target.fields.file["en-US"].contentType === "audio/mpeg") {
          return (
            <div className="audio_container_2">
              <AudioPlayer showSkipControls={false}
                autoPlay
                src={node.data.target.fields.file["en-US"].url}
                style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
              />
            </div>
          )
        } else {
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        }
      },
      [INLINES.HYPERLINK]: (node) => {
        if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && ((node.data.uri).includes("<a")))) {
          const urlVimeo = node.data.uri.split('iframe')
          let urlVimeoEmbed;
          for (let i = 0; i < urlVimeo.length; i++) {
            if ((urlVimeo[i]).includes("src=")) {
              urlVimeoEmbed = urlVimeo[i]
            }
          }
          function createMarkup() { return { __html: `<iframe ${urlVimeoEmbed} iframe>` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && !((node.data.uri).includes("<a")))) {
          return null
        } else if ((node.data.uri).includes("<iframe") && !(node.data.uri).includes("vimeo")) {
          function createMarkup() { return { __html: `${node.data.uri}` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else {
          return <a href={node.data.uri} style={{ 'color': 'red' }} target="_blank" rel="noreferrer"><b>{node.content[0].value}</b></a>
        }
      },
    }
  }

  const optionsModal10 = {
    renderNode: {
      [BLOCKS.HR]: (node, children) => {
        if (DisplayClassModal10 === false) {
          return setDisplayClassModal10(true)
        } else if (DisplayClassModal10 === true) {
          return false
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        if (node.data.target.fields.title["en-US"].includes('diseño') || node.data.target.fields.title["en-US"].includes('diseño2')) {
          if (ImageModal10 === undefined) {
            setImageModal10(node.data.target)
          }
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        } else if (node.data.target.fields.file["en-US"].contentType === "audio/mpeg") {
          return (
            <div className="audio_container_2">
              <AudioPlayer showSkipControls={false}
                autoPlay
                src={node.data.target.fields.file["en-US"].url}
                style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
              />
            </div>
          )
        } else {
          return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
        }
      },
      [INLINES.HYPERLINK]: (node) => {
        if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && ((node.data.uri).includes("<a")))) {
          const urlVimeo = node.data.uri.split('iframe')
          let urlVimeoEmbed;
          for (let i = 0; i < urlVimeo.length; i++) {
            if ((urlVimeo[i]).includes("src=")) {
              urlVimeoEmbed = urlVimeo[i]
            }
          }
          function createMarkup() { return { __html: `<iframe ${urlVimeoEmbed} iframe>` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else if (((node.data.uri).includes("<iframe") && (node.data.uri).includes("vimeo") && !((node.data.uri).includes("<a")))) {
          return null
        } else if ((node.data.uri).includes("<iframe") && !(node.data.uri).includes("vimeo")) {
          function createMarkup() { return { __html: `${node.data.uri}` }; };
          return <div dangerouslySetInnerHTML={createMarkup()} />
        } else {
          return <a href={node.data.uri} style={{ 'color': 'red' }} target="_blank" rel="noreferrer"><b>{node.content[0].value}</b></a>
        }
      }
    }
  }




  useEffect(() => {
    if (size.width > 991) {
      var body = document.body;
      body.classList.add("disableScroll");
    }
    return () => {
      if (size.width > 991) {

        body.classList.remove("disableScroll");
      }

    }
  }, [size.width])

  return (
    <Layout
      description={subtitulo}
      title={tItulo}
      subtitile={subtitulo}
      imgurl={imagendelcarrusel.file.url}
      metaTitle={metaTitle}
      metaKeywords={metaKeywords}
      metaDescripcin={metaDescripcin}
    >
      <div className="template_2">
        <h1 className="d-none">{tItulo}</h1>
        <h2 className="d-none">{subtitulo}</h2>
        <h3 className="d-none">{metaDescripcin}</h3>
        <ShareButton url={pathname} iconColour={'#000'} />
        {backgroundsImage[0] ?
          <BackgroundImage className="vh-100style background_image page1 page1_template_2" id="page1IDTemplate2" fluid={backgroundsImage[0].fluid}>
            <div className="controls-page">
              <div className="control-container">
                <button type="button" onClick={() => { navigate('#page2IDTemplate2'); }} className="boton-scroll-down"><img src={Arrow_Down} alt="Flecha abajo" /></button>
              </div>
            </div>
            <div className="container-img">
              <div className="imagen_1">
                <Image fluid={imagen1.fluid} alt={imagen1.description ? imagen1.description : imagen1.file.fileName} />
              </div>
              <div className="imagen_2">
                <Image fluid={imagen2.fluid} alt={imagen2.description ? imagen2.description : imagen2.file.fileName} />
              </div>
            </div>
          </BackgroundImage> : null
        }
        {backgroundsImage[1] ?
          <BackgroundImage className="vh-100style background_image page2 page2_template_2" id="page2IDTemplate2" fluid={backgroundsImage[1].fluid}>
            <div className="controls-page">
              <div className="control-container">
                <button type="button" onClick={() => { navigate('#page1IDTemplate2'); }} className="boton-scroll-top"><img src={Arrow_Up} alt="Flecha arriba" /></button>
                <button type="button" onClick={() => { navigate('#page3IDTemplate2'); }} className="boton-scroll-down"><img src={Arrow_Down} alt="Flecha abajo" /></button>
              </div>
            </div>
            <div className="container-img">
              <div className="imagen_3">
                <Image fluid={imagen3.fluid} alt={imagen3.description ? imagen3.description : imagen3.file.fileName} />
              </div>
            </div>
          </BackgroundImage> : null
        }




        {backgroundsImage[2] ?
          <BackgroundImage className="vh-100style background_image page3 page3_template_2" id="page3IDTemplate2" fluid={backgroundsImage[2].fluid}>
            <div className="controls-page">
              <div className="control-container">
                <button type="button" onClick={() => { navigate('#page2IDTemplate2'); }} className="boton-scroll-top"><img src={Arrow_Up} alt="Flecha arriba" /></button>
                <button type="button" onClick={() => { navigate('#page4IDTemplate2'); }} className="boton-scroll-down"><img src={Arrow_Down} alt="Flecha abajo" /></button>
              </div>
            </div>
            {
              ButtonsASectionA &&
              <>
                <div className="button_ornament ornamentA">
                  <div className="ornament">
                    {OrnamentSectionA[0] ?
                      <Image fluid={OrnamentSectionA[0].fluid} alt={OrnamentSectionA[0].description ? OrnamentSectionA[0].description : OrnamentSectionA[0].file.fileName} /> : null
                    }
                  </div>
                </div>
                <div className="button_ornament ornamentB">
                  <div className="ornament">
                    {OrnamentSectionA[0] ?
                      <Image fluid={OrnamentSectionA[1].fluid} alt={OrnamentSectionA[1].description ? OrnamentSectionA[1].description : OrnamentSectionA[1].file.fileName} /> : null
                    }
                  </div>
                </div>
                <div className="button_ornament ornamentC">
                  <div className="ornament">
                    {OrnamentSectionA[0] ?
                      <Image fluid={OrnamentSectionA[2].fluid} alt={OrnamentSectionA[2].description ? OrnamentSectionA[2].description : OrnamentSectionA[2].file.fileName} /> : null
                    }
                  </div>
                </div>
              </>

            }
            <div className={ButtonsASectionA.length > 6 ? "container-img-type-2" : "container-img"}>
              <div className="title_section_A">
                <Image fluid={titlesectionA.fluid} alt={titlesectionA.description ? titlesectionA.description : titlesectionA.file.fileName} />
              </div>

              <div className="buttons_container">
                {ButtonsASectionA.map((ButtonImage, i) =>
                  <React.Fragment key={i}>
                    {ButtonsASectionA.length !== 3 ?
                      <div className={`button_images button_image_${i} ` + (i === 2 ? "ml-auto" : "") + (i === 6 ? "ml-auto" : "")} onClick={() => {
                        if (i === 0) { handleShowA1() } else if (i === 1) { handleShowA2() } else if (i === 2) { handleShowA3() } else if (i === 3) { handleShowA4() } else if (i === 4) { handleShowA5() } else if (i === 5) { handleShowA6() }
                        else if (i === 6) { handleShowA7() } else if (i === 7) { handleShowA8() } else if (i === 8) { handleShowA9() } else if (i === 9) { handleShowA10() }
                      }}
                        onKeyDown={(ev) => {
                          if (ev.keyCode === 13) {
                            if (i === 0) { handleShowA1() } else if (i === 1) { handleShowA2() } else if (i === 2) { handleShowA3() } else if (i === 3) { handleShowA4() } else if (i === 4) { handleShowA5() } else if (i === 5) { handleShowA6() }
                            else if (i === 6) { handleShowA7() } else if (i === 7) { handleShowA8() } else if (i === 8) { handleShowA9() } else if (i === 9) { handleShowA10() }
                          }
                        }} role="button" tabIndex="0">
                        <Image className="img_in_button" fluid={ButtonImage.fluid} alt={ButtonImage.description ? ButtonImage.description : ButtonImage.file.fileName} />
                        {ButtonsBSectionA[i] ?
                          <Image className="img_in_button_above" fluid={ButtonsBSectionA[i].fluid} alt={ButtonsBSectionA[i].description ? ButtonsBSectionA[i].description : ButtonsBSectionA[i].file.fileName} />
                          : null
                        }
                      </div> :
                      <div className={`button_images button_image_${i}`} onClick={() => {
                        if (i === 0) { handleShowA1() } else if (i === 1) { handleShowA2() } else if (i === 2) { handleShowA3() } else if (i === 3) { handleShowA4() } else if (i === 4) { handleShowA5() } else if (i === 5) { handleShowA6() }
                        else if (i === 6) { handleShowA7() } else if (i === 7) { handleShowA8() } else if (i === 8) { handleShowA9() } else if (i === 9) { handleShowA10() }
                      }}
                        onKeyDown={(ev) => {
                          if (ev.keyCode === 13) {
                            if (i === 0) { handleShowA1() } else if (i === 1) { handleShowA2() } else if (i === 2) { handleShowA3() } else if (i === 3) { handleShowA4() } else if (i === 4) { handleShowA5() } else if (i === 5) { handleShowA6() }
                            else if (i === 6) { handleShowA7() } else if (i === 7) { handleShowA8() } else if (i === 8) { handleShowA9() } else if (i === 9) { handleShowA10() }
                          }
                        }} role="button" tabIndex="0">
                        <Image className="img_in_button" fluid={ButtonImage.fluid} alt={ButtonImage.description ? ButtonImage.description : ButtonImage.file.fileName} />
                        {ButtonsBSectionA[i] ?
                          <Image className="img_in_button_above" fluid={ButtonsBSectionA[i].fluid} alt={ButtonsBSectionA[i].description ? ButtonsBSectionA[i].description : ButtonsBSectionA[i].file.fileName} />
                          : null
                        }
                      </div>
                    }
                  </React.Fragment>
                )}
              </div>
            </div>
          </BackgroundImage> : null
        }


        {backgroundsImage[3] ?
          <BackgroundImage className="vh-100style background_image page4 page4_template_2" id="page4IDTemplate2" fluid={backgroundsImage[3].fluid}>
            <div className="controls-page">
              <div className="control-container">
                <button type="button" onClick={() => { navigate('#page3IDTemplate2'); }} className="boton-scroll-top"><img src={Arrow_Up} alt="Flecha arriba" /></button>
                <button type="button" onClick={() => { navigate('#page5IDTemplate2'); }} className="boton-scroll-down"><img src={Arrow_Down} alt="Flecha abajo" /></button>
              </div>
            </div>
            {
              OrnamentSectionB &&
              <>
                <div className="button_ornament ornamentA">
                  <div className="ornament">
                    {OrnamentSectionA[0] ?
                      <Image fluid={OrnamentSectionB[0].fluid} alt={OrnamentSectionB[0].description ? OrnamentSectionB[0].description : OrnamentSectionB[0].file.fileName} /> : null
                    }
                  </div>
                </div>
                <div className="button_ornament ornamentB">
                  <div className="ornament">
                    {OrnamentSectionA[0] ?
                      <Image fluid={OrnamentSectionB[1].fluid} alt={OrnamentSectionB[1].description ? OrnamentSectionB[1].description : OrnamentSectionB[1].file.fileName} /> : null
                    }
                  </div>
                </div>
                <div className="button_ornament ornamentC">
                  <div className="ornament">
                    {OrnamentSectionA[0] ?
                      <Image fluid={OrnamentSectionB[2].fluid} alt={OrnamentSectionB[2].description ? OrnamentSectionB[2].description : OrnamentSectionB[2].file.fileName} /> : null
                    }
                  </div>
                </div>
              </>
            }
            <div className="container-img">
              <div className="title_section_B">
                <Image fluid={sectionBtitle.fluid} alt={sectionBtitle.description ? sectionBtitle.description : sectionBtitle.file.fileName} />
              </div>
              <div className="buttons_container">
                {ButtonsASectionB.map((ButtonImage, i) =>
                  <div className={`button_images button_image_${i}`} key={i} onClick={() => {
                    if (i === 0) { handleShowB1() } else if (i === 1) { handleShowB2() } else if (i === 2) { handleShowB3() } else if (i === 3) { handleShowB4() } else if (i === 4) { handleShowB5() } else if (i === 5) { handleShowB6() }
                    else if (i === 6) { handleShowB7() } else if (i === 7) { handleShowB8() } else if (i === 8) { handleShowB9() } else if (i === 9) { handleShowB10() }
                  }}
                    onKeyDown={(ev) => {
                      if (ev.keyCode === 13) {
                        if (i === 0) { handleShowB1() } else if (i === 1) { handleShowB2() } else if (i === 2) { handleShowB3() } else if (i === 3) { handleShowB4() } else if (i === 4) { handleShowB5() } else if (i === 5) { handleShowB6() }
                        else if (i === 6) { handleShowB7() } else if (i === 7) { handleShowB8() } else if (i === 8) { handleShowB9() } else if (i === 9) { handleShowB10() }
                      }
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    <Image className="img_in_button" fluid={ButtonImage.fluid} alt={ButtonImage.description ? ButtonImage.description : ButtonImage.file.fileName} />
                    {ButtonsBSectionB[i] ?
                      <Image className="img_in_button_above" fluid={ButtonsBSectionB[i].fluid} alt={ButtonsBSectionB[i].description ? ButtonsBSectionB[i].description : ButtonsBSectionB[i].file.fileName} />
                      : null
                    }
                  </div>
                )}
              </div>
            </div>
          </BackgroundImage> : null
        }


        {backgroundsImage[4] ?
          <BackgroundImage className="vh-100style background_image page5 page5_template_2" id="page5IDTemplate2" fluid={backgroundsImage[4].fluid}>
            <div className="controls-page">
              <div className="control-container">
                <button type="button" onClick={() => { navigate('#page4IDTemplate2'); }} className="boton-scroll-top"><img src={Arrow_Up} alt="Flecha arriba" /></button>
              </div>
            </div>
            <div className="container-img">
              <div className="button_container_1 button_container" onClick={handleShowC1} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShowC1() } }} role="button" tabIndex="0">
                <Image className="img_button" fluid={ButtonsASectionC[0].fluid} />
                <Image className="img_button_above" fluid={ButtonsBSectionC[0].fluid} />
              </div>
              <div className="button_container_2 button_container" onClick={handleShowC2} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShowC2() } }} role="button" tabIndex="0">
                <Image className="img_button" fluid={ButtonsASectionC[1].fluid} />
                <Image className="img_button_above" fluid={ButtonsBSectionC[1].fluid} />
              </div>
            </div>
          </BackgroundImage> : null
        }

        {
          seccionCmodal1 ?
            <>
              {seccionCmodal1.length === 2 &&
                <Modal className="sectioncmodal" show={showC1} onHide={handleCloseC1}>

                  {seccionCmodal1 ?
                    <BackgroundImage className="sectioncmodal_container modal-body" fluid={backgroundsImage[7].fluid} alt={backgroundsImage[7].description ? backgroundsImage[7].description : backgroundsImage[7].file.fileName}>
                      <div className="close_button_container" onClick={handleCloseC1} role="button" tabIndex="0" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseC1() } }}>
                        <Image fluid={iconCloseModal.fluid} />
                      </div>
                      {seccionCmodal1[1] ?
                        <div className="title_responsive">
                          <Image fluid={seccionCmodal1[1].fluid} alt={seccionCmodal1[1].description ? seccionCmodal1[3].description : seccionCmodal1[1].file.fileName} />
                        </div> : null
                      }
                      <div className="modal_subcontainer">
                        {seccionCmodal1[0] ?
                          <div className="person_img_container">
                            <Image fluid={seccionCmodal1[0].fluid} alt={seccionCmodal1[0].description ? seccionCmodal1[3].description : seccionCmodal1[0].file.fileName} />
                          </div>
                          : null}
                        {seccionCmodal1[1] ?
                          <div className="title_container">
                            {seccionCmodal1[1] ?
                              <div className="title">
                                <Image fluid={seccionCmodal1[1].fluid} alt={seccionCmodal1[1].description ? seccionCmodal1[3].description : seccionCmodal1[1].file.fileName} />
                              </div> : null
                            }

                            {/*seccionCmodal1Links*/}
                            {seccionCmodal1Text ?
                              <>
                                {seccionCmodal1Text.json ?
                                  <div className="description">{documentToReactComponents(seccionCmodal1Text.json)}</div> : null
                                }
                              </>
                              : null
                            }
                            <div className="buttons_redirect_container">
                              {
                                NameLinksModal1 ?
                                  <>
                                    {NameLinksModal1.length === LinksModal1.length ?
                                      <>
                                        {NameLinksModal1.length > 0 &&
                                          <>
                                            {NameLinksModal1.map((NameLinkModal, i) =>
                                              <React.Fragment key={i}>
                                                {NameLinkModal.trim() === 'linktree' ?
                                                  <a target="_blank" rel="noreferrer" href={LinksModal1[i]}><img src="/images/botones-redes_03.png" alt="linktree"></img></a> : null
                                                }
                                                {NameLinkModal.trim() === 'featurefm' ?
                                                  <a target="_blank" rel="noreferrer" href={LinksModal1[i]}><img src="/images/botones-redes_04.png" alt="featurefm"></img></a> : null
                                                }
                                                {NameLinkModal.trim() === 'facebook' ?
                                                  <a target="_blank" rel="noreferrer" href={LinksModal1[i]}><img src="/images/botones-redes_05.png" alt="facebook"></img></a> : null
                                                }
                                                {NameLinkModal.trim() === 'twitter' ?
                                                  <a target="_blank" rel="noreferrer" href={LinksModal1[i]}><img src="/images/botones-redes_07.png" alt="twitter"></img></a> : null
                                                }
                                                {NameLinkModal.trim() === 'youtube' ?
                                                  <a target="_blank" rel="noreferrer" href={LinksModal1[i]}><img src="/images/botones-redes_09.png" alt="youtube"></img></a> : null
                                                }
                                                {NameLinkModal.trim() === 'instagram' ?
                                                  <a target="_blank" rel="noreferrer" href={LinksModal1[i]}><img src="/images/botones-redes_08.png" alt="instagram"></img></a> : null
                                                }
                                                {NameLinkModal.trim() === 'spotify' ?
                                                  <a target="_blank" rel="noreferrer" href={LinksModal1[i]}><img src="/images/botones-redes_10.png" alt="spotify"></img></a> : null
                                                }
                                                {NameLinkModal.trim() === 'applemusic' ?
                                                  <a target="_blank" rel="noreferrer" href={LinksModal1[i]}><img src="/images/botones-redes_11.png" alt="applemusic"></img></a> : null
                                                }
                                                {NameLinkModal.trim() === 'deezer' ?
                                                  <a target="_blank" rel="noreferrer" href={LinksModal1[i]}><img src="/images/botones-redes_12.png" alt="deezer"></img></a> : null
                                                }
                                              </React.Fragment>
                                            )}
                                          </>
                                        }
                                      </>

                                      : null

                                    }
                                  </> : null
                              }
                            </div>
                          </div>
                          : null}
                      </div>
                    </BackgroundImage> :
                    null
                  }

                </Modal>
              }</>
            : null
        }

        {seccionCmodal2Part1 ?
          <>
            <Modal show={showC2} className="sectionc2modal2" onHide={handleCloseC2}>
              <BackgroundImage className="modal-body" fluid={backgroundsImage[8].fluid} alt={backgroundsImage[8].description ? backgroundsImage[8].description : backgroundsImage[8].file.fileName}>
                <div className="close_button_container" onClick={handleCloseC2} role="button" tabIndex="0" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseC2() } }}>
                  <Image fluid={iconCloseModal.fluid} />
                </div>
                {seccionCmodal2Part1[0] ?
                  <div className="title_modal first_title_modal">
                    <Image fluid={seccionCmodal2Part1[0].fluid} />
                  </div> : null

                }
                <div className="ormament">
                  {seccionCmodal2Part1[1] ?
                    <Image fluid={seccionCmodal2Part1[1].fluid} /> : null
                  }
                </div>
                <div className="singers_container">
                  {seccionCmodal2Part1.map((SeccionCImagesCard, i) =>
                    <React.Fragment key={i}>
                      {i >= 2 &&
                        <div className="singer_container">
                          <Image fluid={SeccionCImagesCard.fluid} />
                        </div>
                      }
                    </React.Fragment>
                  )}
                </div>
                {seccionCmodal2Part2 ?
                  <>
                    <div className="title_modal">
                      {seccionCmodal2Part2[0] ?
                        <Image fluid={seccionCmodal2Part2[0].fluid} /> : null
                      }
                    </div>
                    <div className="ormament">
                      {seccionCmodal2Part2[1] ?
                        <Image fluid={seccionCmodal2Part2[1].fluid} /> : null
                      }
                    </div>
                    <div className="credits_container">
                      <div className="credit_container">
                        {seccionCmodal2Part2[2] ?
                          <Image fluid={seccionCmodal2Part2[2].fluid} /> : null
                        }
                      </div>
                      <div className="credit_container">
                        {seccionCmodal2Part2[3] ?
                          <Image fluid={seccionCmodal2Part2[3].fluid} /> : null
                        }
                      </div>
                    </div>
                  </> : null
                }
                <div className="title_modal">
                  {seccionCmodal2Part3[0] ?
                    <Image fluid={seccionCmodal2Part3[0].fluid} /> : null
                  }
                </div>
                <div className="ormament">
                  {seccionCmodal2Part3[1] ?
                    <Image fluid={seccionCmodal2Part3[1].fluid} /> : null
                  }
                </div>
                <div className="videos_container">
                  {seccionCmodal2Part3.map((seccionCmodalVideosTitle, i) =>
                    <React.Fragment key={i}>
                      {i >= 2 &&
                        <div className="video_container" >
                          {seccionCmodal2Part3Links[i - 2] ?
                            <div className="embed-modal-container">
                              <iframe title={tItulo} width="560" height="315" frameBorder="0" src={`${seccionCmodal2Part3Links[i - 2]}?title=false&portrait=false&byline=false`} />
                            </div>
                            : null
                          }
                          {seccionCmodalVideosTitle ?
                            <div className="title_video_modal">
                              <Image fluid={seccionCmodalVideosTitle.fluid} />
                            </div> : null
                          }
                        </div>
                      }
                    </React.Fragment>
                  )}
                </div>
              </BackgroundImage>
            </Modal>
          </>
          : null

        }

        {
          seccionBmodal1 ?
            <>
              {(seccionBmodal1.length === 3 || seccionBmodal1.length === 4) &&
                <Modal className="sectionbmodal" onHide={handleCloseB1} show={showB1} >
                  <Modal.Body >
                    <div>
                      {backgroundsImage[6] ?
                        <BackgroundImage fluid={backgroundsImage[6].fluid} alt={backgroundsImage[6].description ? backgroundsImage[6].description : backgroundsImage[6].file.fileName}>
                          <div className="title_container" onClick={handleCloseB1} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB1() } }} tabIndex="0">
                            {seccionBmodal1[0] ?
                              <Image className="name_container" fluid={seccionBmodal1[0].fluid} alt={seccionBmodal1[0].description ? seccionBmodal1[3].description : seccionBmodal1[0].file.fileName} />
                              : null}
                            {seccionBmodal1[1] ?
                              <Image className="description_container" fluid={seccionBmodal1[1].fluid} alt={seccionBmodal1[1].description ? seccionBmodal1[3].description : seccionBmodal1[1].file.fileName} />
                              : null}
                          </div>
                          {seccionBmodal1[3] ?
                            <>
                              {
                                seccionBmodal1[3].file.fileName.includes('.mp3') &&
                                <div className="audio_container_2">
                                  <AudioPlayer showSkipControls={false}
                                    autoPlay
                                    src={seccionBmodal1[3].file.url}
                                    style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
                                  />
                                </div>
                              }
                            </> : null
                          }
                          {seccionBmodal1[2] ?
                            <div onClick={handleCloseB1} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB1() } }} tabIndex="0">
                              <Image className="modal_end_image_B" fluid={seccionBmodal1[2].fluid} alt={seccionBmodal1[2].description ? seccionBmodal1[3].description : seccionBmodal1[2].file.fileName} />
                            </div>
                            : null}
                        </BackgroundImage> :
                        null
                      }
                    </div>
                  </Modal.Body>
                </Modal>
              }</>
            : null
        }
        {
          seccionBmodal2 ?
            <>
              {(seccionBmodal2.length === 3 || seccionBmodal2.length === 4) &&
                <Modal className="sectionbmodal" show={showB2} onHide={handleCloseB2}>
                  <Modal.Body>
                    <div>
                      {backgroundsImage[6] ?
                        <BackgroundImage fluid={backgroundsImage[6].fluid} alt={backgroundsImage[6].description ? backgroundsImage[6].description : backgroundsImage[6].file.fileName}>
                          <div className="title_container" onClick={handleCloseB2} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB2() } }} tabIndex="0">
                            {seccionBmodal2[0] ?
                              <Image className="name_container" fluid={seccionBmodal2[0].fluid} alt={seccionBmodal2[0].description ? seccionBmodal2[3].description : seccionBmodal2[0].file.fileName} />
                              : null}
                            {seccionBmodal2[1] ?
                              <Image className="description_container" fluid={seccionBmodal2[1].fluid} alt={seccionBmodal2[1].description ? seccionBmodal2[3].description : seccionBmodal2[1].file.fileName} />
                              : null}
                          </div>
                          {seccionBmodal2[3] ?
                            <>
                              {
                                seccionBmodal2[3].file.fileName.includes('.mp3') &&
                                <div className="audio_container_2">
                                  <AudioPlayer showSkipControls={false} style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
                                    autoPlay
                                    src={seccionBmodal2[3].file.url}
                                  />
                                </div>
                              }
                            </> : null
                          }
                          {seccionBmodal2[2] ?
                            <div onClick={handleCloseB2} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB2() } }} tabIndex="0">
                              <Image className="modal_end_image_B" fluid={seccionBmodal2[2].fluid} alt={seccionBmodal2[2].description ? seccionBmodal2[3].description : seccionBmodal2[2].file.fileName} />
                            </div>
                            : null}
                        </BackgroundImage> :
                        null
                      }
                    </div>
                  </Modal.Body>
                </Modal>
              }
            </>
            : null
        }

        {
          seccionBmodal3 ?
            <>{(seccionBmodal3.length === 3 || seccionBmodal3.length === 4) &&
              <Modal className="sectionbmodal" show={showB3} onHide={handleCloseB3}>
                <Modal.Body>
                  <div>
                    {backgroundsImage[6] ?
                      <BackgroundImage fluid={backgroundsImage[6].fluid} alt={backgroundsImage[6].description ? backgroundsImage[6].description : backgroundsImage[6].file.fileName}>
                        <div className="title_container" onClick={handleCloseB3} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB3() } }} tabIndex="0">
                          {seccionBmodal3[0] ?
                            <Image className="name_container" fluid={seccionBmodal3[0].fluid} alt={seccionBmodal3[0].description ? seccionBmodal3[3].description : seccionBmodal3[0].file.fileName} />
                            : null}
                          {seccionBmodal3[1] ?
                            <Image className="description_container" fluid={seccionBmodal3[1].fluid} alt={seccionBmodal3[1].description ? seccionBmodal3[3].description : seccionBmodal3[1].file.fileName} />
                            : null}
                        </div>
                        {seccionBmodal3[3] ?
                          <>
                            {
                              seccionBmodal3[3].file.fileName.includes('.mp3') &&
                              <div className="audio_container_2">
                                <AudioPlayer showSkipControls={false} style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
                                  autoPlay
                                  src={seccionBmodal3[3].file.url}
                                />
                              </div>
                            }
                          </> : null
                        }
                        {seccionBmodal3[2] ?
                          <div onClick={handleCloseB3} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB3() } }} tabIndex="0">
                            <Image className="modal_end_image_B" fluid={seccionBmodal3[2].fluid} alt={seccionBmodal3[2].description ? seccionBmodal3[3].description : seccionBmodal3[2].file.fileName} />
                          </div>
                          : null}
                      </BackgroundImage> :
                      null
                    }
                  </div>
                </Modal.Body>
              </Modal>
            }</>
            : null
        }

        {
          seccionBmodal4 ?
            <>
              {(seccionBmodal4.length === 3 || seccionBmodal4.length === 4) &&
                <Modal className="sectionbmodal" show={showB4} onHide={handleCloseB4}>
                  <Modal.Body>
                    <div>
                      {backgroundsImage[6] ?
                        <BackgroundImage fluid={backgroundsImage[6].fluid} alt={backgroundsImage[6].description ? backgroundsImage[6].description : backgroundsImage[6].file.fileName}>
                          <div className="title_container" onClick={handleCloseB4} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB4() } }} tabIndex="0">
                            {seccionBmodal4[0] ?
                              <Image className="name_container" fluid={seccionBmodal4[0].fluid} alt={seccionBmodal4[0].description ? seccionBmodal4[3].description : seccionBmodal4[0].file.fileName} />
                              : null}
                            {seccionBmodal4[1] ?
                              <Image className="description_container" fluid={seccionBmodal4[1].fluid} alt={seccionBmodal4[1].description ? seccionBmodal4[3].description : seccionBmodal4[1].file.fileName} />
                              : null}
                          </div>
                          {seccionBmodal4[3] ?
                            <>
                              {
                                seccionBmodal4[3].file.fileName.includes('.mp3') &&
                                <div className="audio_container_2">
                                  <AudioPlayer showSkipControls={false} style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
                                    autoPlay
                                    src={seccionBmodal4[3].file.url}
                                  />
                                </div>
                              }
                            </> : null
                          }
                          {seccionBmodal4[2] ?
                            <div onClick={handleCloseB4} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB4() } }} tabIndex="0">
                              <Image className="modal_end_image_B" fluid={seccionBmodal4[2].fluid} alt={seccionBmodal4[2].description ? seccionBmodal4[3].description : seccionBmodal4[2].file.fileName} />
                            </div>
                            : null}
                        </BackgroundImage> :
                        null
                      }
                    </div>
                  </Modal.Body>
                </Modal>
              }
            </>
            : null
        }

        {
          seccionBmodal5 ?
            <>
              {(seccionBmodal5.length === 3 || seccionBmodal5.length === 4) &&
                <Modal className="sectionbmodal" show={showB5} onHide={handleCloseB5}>
                  <Modal.Body >
                    <div>
                      {backgroundsImage[6] ?
                        <BackgroundImage fluid={backgroundsImage[6].fluid} alt={backgroundsImage[6].description ? backgroundsImage[6].description : backgroundsImage[6].file.fileName}>
                          <div className="title_container" onClick={handleCloseB5} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB5() } }} tabIndex="0">
                            {seccionBmodal5[0] ?
                              <Image className="name_container" fluid={seccionBmodal5[0].fluid} alt={seccionBmodal5[0].description ? seccionBmodal5[3].description : seccionBmodal5[0].file.fileName} />
                              : null}
                            {seccionBmodal5[1] ?
                              <Image className="description_container" fluid={seccionBmodal5[1].fluid} alt={seccionBmodal5[1].description ? seccionBmodal5[3].description : seccionBmodal5[1].file.fileName} />
                              : null}
                          </div>
                          {seccionBmodal5[3] ?
                            <>
                              {
                                seccionBmodal5[3].file.fileName.includes('.mp3') &&
                                <div className="audio_container_2">
                                  <AudioPlayer showSkipControls={false} style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
                                    autoPlay
                                    src={seccionBmodal5[3].file.url}
                                  />
                                </div>
                              }
                            </> : null
                          }
                          {seccionBmodal5[2] ?
                            <div onClick={handleCloseB5} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB5() } }} tabIndex="0">
                              <Image className="modal_end_image_B" fluid={seccionBmodal5[2].fluid} alt={seccionBmodal5[2].description ? seccionBmodal5[3].description : seccionBmodal5[2].file.fileName} />
                            </div>
                            : null}
                        </BackgroundImage> :
                        null
                      }
                    </div>
                  </Modal.Body>
                </Modal>
              }
            </>
            : null
        }

        {
          seccionBmodal6 ?
            <>
              {(seccionBmodal6.length === 3 || seccionBmodal6.length === 4) &&
                <Modal className="sectionbmodal" show={showB6} onHide={handleCloseB6}>
                  <Modal.Body>
                    <div>
                      {backgroundsImage[6] ?
                        <BackgroundImage fluid={backgroundsImage[6].fluid} alt={backgroundsImage[6].description ? backgroundsImage[6].description : backgroundsImage[6].file.fileName}>
                          <div className="title_container" onClick={handleCloseB6} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB6() } }} tabIndex="0">
                            {seccionBmodal6[0] ?
                              <Image className="name_container" fluid={seccionBmodal6[0].fluid} alt={seccionBmodal6[0].description ? seccionBmodal6[3].description : seccionBmodal6[0].file.fileName} />
                              : null}
                            {seccionBmodal6[1] ?
                              <Image className="description_container" fluid={seccionBmodal6[1].fluid} alt={seccionBmodal6[1].description ? seccionBmodal6[3].description : seccionBmodal6[1].file.fileName} />
                              : null}
                          </div>
                          {seccionBmodal6[3] ?
                            <>
                              {
                                seccionBmodal6[3].file.fileName.includes('.mp3') &&
                                <div className="audio_container_2">
                                  <AudioPlayer showSkipControls={false} style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
                                    autoPlay
                                    src={seccionBmodal6[3].file.url}
                                  />
                                </div>
                              }
                            </> : null
                          }
                          {seccionBmodal6[2] ?
                            <div onClick={handleCloseB6} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB6() } }} tabIndex="0">
                              <Image className="modal_end_image_B" fluid={seccionBmodal6[2].fluid} alt={seccionBmodal6[2].description ? seccionBmodal6[3].description : seccionBmodal6[2].file.fileName} />
                            </div>
                            : null}
                        </BackgroundImage> :
                        null
                      }
                    </div>
                  </Modal.Body>
                </Modal>
              }
            </>
            : null
        }

        {
          seccionBmodal7 ?
            <>{(seccionBmodal7.length === 3 || seccionBmodal7.length === 4) &&
              <Modal className="sectionbmodal" show={showB7} onHide={handleCloseB7}>
                <Modal.Body>
                  <div>
                    {backgroundsImage[6] ?
                      <BackgroundImage fluid={backgroundsImage[6].fluid} alt={backgroundsImage[6].description ? backgroundsImage[6].description : backgroundsImage[6].file.fileName}>
                        <div className="title_container" onClick={handleCloseB7} role="button" tabIndex="0" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB7() } }}>
                          {seccionBmodal7[0] ?
                            <Image className="name_container" fluid={seccionBmodal7[0].fluid} alt={seccionBmodal7[0].description ? seccionBmodal7[3].description : seccionBmodal7[0].file.fileName} />
                            : null}
                          {seccionBmodal7[1] ?
                            <Image className="description_container" fluid={seccionBmodal7[1].fluid} alt={seccionBmodal7[1].description ? seccionBmodal7[3].description : seccionBmodal7[1].file.fileName} />
                            : null}
                        </div>
                        {seccionBmodal7[3] ?
                          <>
                            {
                              seccionBmodal7[3].file.fileName.includes('.mp3') &&
                              <div className="audio_container_2">
                                <AudioPlayer showSkipControls={false} style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
                                  autoPlay
                                  src={seccionBmodal7[3].file.url}
                                />
                              </div>
                            }
                          </> : null
                        }
                        {seccionBmodal7[2] ?
                          <div onClick={handleCloseB7} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB7() } }} tabIndex="0">
                            <Image className="modal_end_image_B" fluid={seccionBmodal7[2].fluid} alt={seccionBmodal7[2].description ? seccionBmodal7[3].description : seccionBmodal7[2].file.fileName} />
                          </div>
                          : null}
                      </BackgroundImage> :
                      null
                    }
                  </div>
                </Modal.Body>
              </Modal>
            }</>
            : null
        }

        {
          seccionBmodal8 ?
            <>{(seccionBmodal8.length === 3 || seccionBmodal8.length === 4) &&
              <Modal className="sectionbmodal" show={showB8} onHide={handleCloseB8}>
                <Modal.Body>
                  <div>
                    {backgroundsImage[6] ?
                      <BackgroundImage fluid={backgroundsImage[6].fluid} alt={backgroundsImage[6].description ? backgroundsImage[6].description : backgroundsImage[6].file.fileName}>
                        <div className="title_container" onClick={handleCloseB8} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB8() } }} tabIndex="0">
                          {seccionBmodal8[0] ?
                            <Image className="name_container" fluid={seccionBmodal8[0].fluid} alt={seccionBmodal8[0].description ? seccionBmodal8[3].description : seccionBmodal8[0].file.fileName} />
                            : null}
                          {seccionBmodal8[1] ?
                            <Image className="description_container" fluid={seccionBmodal8[1].fluid} alt={seccionBmodal8[1].description ? seccionBmodal8[3].description : seccionBmodal8[1].file.fileName} />
                            : null}
                        </div>
                        {seccionBmodal8[3] ?
                          <>
                            {
                              seccionBmodal8[3].file.fileName.includes('.mp3') &&
                              <div className="audio_container_2">
                                <AudioPlayer showSkipControls={false} style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
                                  autoPlay
                                  src={seccionBmodal8[3].file.url}
                                />
                              </div>
                            }
                          </> : null
                        }
                        {seccionBmodal8[2] ?
                          <div onClick={handleCloseB8} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB8() } }} tabIndex="0">
                            <Image className="modal_end_image_B" fluid={seccionBmodal8[2].fluid} alt={seccionBmodal8[2].description ? seccionBmodal8[3].description : seccionBmodal8[2].file.fileName} />
                          </div>
                          : null}
                      </BackgroundImage> :
                      null
                    }
                  </div>
                </Modal.Body>
              </Modal>
            }</>
            : null
        }

        {
          seccionBmodal9 ?
            <>
              {(seccionBmodal9.length === 3 || seccionBmodal9.length === 4) &&
                <Modal className="sectionbmodal" show={showB9} onHide={handleCloseB9}>
                  <Modal.Body>
                    <div>
                      {backgroundsImage[6] ?
                        <BackgroundImage fluid={backgroundsImage[6].fluid} alt={backgroundsImage[6].description ? backgroundsImage[6].description : backgroundsImage[6].file.fileName}>
                          <div className="title_container" role="button" onClick={handleCloseB9} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB9() } }} tabIndex="0">
                            {seccionBmodal9[0] ?
                              <Image className="name_container" fluid={seccionBmodal9[0].fluid} alt={seccionBmodal9[0].description ? seccionBmodal9[3].description : seccionBmodal9[0].file.fileName} />
                              : null}
                            {seccionBmodal9[1] ?
                              <Image className="description_container" fluid={seccionBmodal9[1].fluid} alt={seccionBmodal9[1].description ? seccionBmodal9[3].description : seccionBmodal9[1].file.fileName} />
                              : null}
                          </div>
                          {seccionBmodal9[3] ?
                            <>
                              {
                                seccionBmodal9[3].file.fileName.includes('.mp3') &&
                                <div className="audio_container_2">
                                  <AudioPlayer showSkipControls={false} style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
                                    autoPlay
                                    src={seccionBmodal9[3].file.url}
                                  />
                                </div>
                              }
                            </> : null
                          }
                          {seccionBmodal9[2] ?
                            <div onClick={handleCloseB9} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB9() } }} tabIndex="0">
                              <Image className="modal_end_image_B" fluid={seccionBmodal9[2].fluid} alt={seccionBmodal9[2].description ? seccionBmodal9[3].description : seccionBmodal9[2].file.fileName} />
                            </div>
                            : null}
                        </BackgroundImage> :
                        null
                      }
                    </div>
                  </Modal.Body>
                </Modal>
              }
            </>
            : null
        }

        {
          seccionBmodal10 ?
            <>
              {(seccionBmodal10.length === 3 || seccionBmodal10.length === 4) &&
                <Modal className="sectionbmodal" show={showB10} onHide={handleCloseB10}>
                  <Modal.Body>
                    <div>
                      {backgroundsImage[6] ?
                        <BackgroundImage fluid={backgroundsImage[6].fluid} alt={backgroundsImage[6].description ? backgroundsImage[6].description : backgroundsImage[6].file.fileName}>
                          <div className="title_container" role="button" onClick={handleCloseB10} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB10() } }} tabIndex="0">
                            {seccionBmodal10[0] ?
                              <Image className="name_container" fluid={seccionBmodal10[0].fluid} alt={seccionBmodal10[0].description ? seccionBmodal10[3].description : seccionBmodal10[0].file.fileName} />
                              : null}
                            {seccionBmodal10[1] ?
                              <Image className="description_container" fluid={seccionBmodal10[1].fluid} alt={seccionBmodal10[1].description ? seccionBmodal10[3].description : seccionBmodal10[1].file.fileName} />
                              : null}
                          </div>
                          {seccionBmodal10[3] ?
                            <>
                              {
                                seccionBmodal10[3].file.fileName.includes('.mp3') &&
                                <div className="audio_container_2">
                                  <AudioPlayer showSkipControls={false} style={{ background: 'unset', boxShadow: 'unset', width: '100%', margin: '0 auto', padding: '0', lineHeight: '0' }}
                                    autoPlay
                                    src={seccionBmodal10[3].file.url}
                                  />
                                </div>
                              }
                            </> : null
                          }
                          {seccionBmodal10[2] ?
                            <div onClick={handleCloseB10} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseB10() } }} tabIndex="0">
                              <Image className="modal_end_image_B" fluid={seccionBmodal10[2].fluid} alt={seccionBmodal10[2].description ? seccionBmodal10[3].description : seccionBmodal10[2].file.fileName} />
                            </div>
                            : null}
                        </BackgroundImage> :
                        null
                      }
                    </div>
                  </Modal.Body>
                </Modal>
              }
            </>
            : null
        }






        <Modal show={showA1} onHide={handleCloseA1} className="modalA1 modalA_Template2">
          <Modal.Body>
            <div>
              {seccionAmodalDivided[0] === 'si' ?
                <div className="display_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA1} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA1() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      <div className="container_sub_richcontext">
                        {documentToReactComponents(seccionAmodal1.json, optionsModal1)}
                      </div>
                      <div className="container_img_modal">
                        {ImageModal1 ?
                          <img src={ImageModal1.fields.file["en-US"].url} alt={tItulo} /> : null
                        }
                      </div>
                    </div>
                  </BackgroundImage>
                </div>
                :
                <div className="single_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA1} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA1() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      {documentToReactComponents(seccionAmodal1.json, optionsModal1)}
                    </div>
                  </BackgroundImage>
                </div>
              }
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showA2} onHide={handleCloseA2} className="modalA2 modalA_Template2">
          <Modal.Body >
            <div>
              {seccionAmodalDivided[1] === 'si' ?
                <div className="display_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA2} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA2() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      <div className="container_sub_richcontext">
                        {documentToReactComponents(seccionAmodal2.json, optionsModal2)}
                      </div>
                      <div className="container_img_modal">
                        {ImageModal2 ?
                          <img src={ImageModal2.fields.file["en-US"].url} alt={tItulo} /> : null
                        }
                      </div>
                    </div>
                  </BackgroundImage>
                </div>
                :
                <div className="single_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA2} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA2() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      {documentToReactComponents(seccionAmodal2.json, optionsModal2)}
                    </div>
                  </BackgroundImage>
                </div>
              }
            </div>
          </Modal.Body>
        </Modal>


        <Modal show={showA3} onHide={handleCloseA3} className="modalA3 modalA_Template2">
          <Modal.Body>
            <div>
              {seccionAmodalDivided[2] === 'si' ?
                <div className="display_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA3} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA3() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      <div className="container_sub_richcontext">
                        {documentToReactComponents(seccionAmodal3.json, optionsModal3)}
                      </div>
                      <div className="container_img_modal">
                        {ImageModal3 ?
                          <img src={ImageModal3.fields.file["en-US"].url} alt={tItulo} /> : null
                        }
                      </div>
                    </div>
                  </BackgroundImage>
                </div>
                :
                <div className="single_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA3} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA3() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      {documentToReactComponents(seccionAmodal3.json, optionsModal3)}
                    </div>
                  </BackgroundImage>
                </div>
              }
            </div>
          </Modal.Body>
        </Modal>


        <Modal show={showA4} onHide={handleCloseA4} className="modalA4 modalA_Template2">
          <Modal.Body>
            <div>
              {seccionAmodalDivided[3] === 'si' ?
                <div className="display_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA4} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA4() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      <div className="container_sub_richcontext">
                        {documentToReactComponents(seccionAmodal4.json, optionsModal4)}
                      </div>
                      <div className="container_img_modal">
                        {ImageModal4 ?
                          <img src={ImageModal4.fields.file["en-US"].url} alt={tItulo} /> : null
                        }
                      </div>
                    </div>
                  </BackgroundImage>
                </div>
                :
                <div className="single_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA4} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA4() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      {documentToReactComponents(seccionAmodal4.json, optionsModal4)}
                    </div>
                  </BackgroundImage>
                </div>
              }
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showA5} onHide={handleCloseA5} className="modalA4 modalA_Template2">
          <Modal.Body>
            <div>
              {seccionAmodalDivided[4] === 'si' ?
                <div className="display_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA5} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA5() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      <div className="container_sub_richcontext">
                        {documentToReactComponents(seccionAmodal5.json, optionsModal5)}
                      </div>
                      <div className="container_img_modal">
                        {ImageModal5 ?
                          <img src={ImageModal5.fields.file["en-US"].url} alt={tItulo} /> : null
                        }
                      </div>
                    </div>
                  </BackgroundImage>
                </div>
                :
                <div className="single_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA5} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA5() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      {documentToReactComponents(seccionAmodal5.json, optionsModal5)}
                    </div>
                  </BackgroundImage>
                </div>
              }
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showA6} onHide={handleCloseA6} className="modalA4 modalA_Template2">
          <Modal.Body>
            <div>
              {seccionAmodalDivided[5] === 'si' ?
                <div className="display_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA6} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA6() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      <div className="container_sub_richcontext">
                        {documentToReactComponents(seccionAmodal6.json, optionsModal6)}
                      </div>
                      <div className="container_img_modal">
                        {ImageModal6 ?
                          <img src={ImageModal6.fields.file["en-US"].url} alt={tItulo} /> : null
                        }
                      </div>
                    </div>
                  </BackgroundImage>
                </div>
                :
                <div className="single_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA6} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA6() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      {documentToReactComponents(seccionAmodal6.json, optionsModal6)}
                    </div>
                  </BackgroundImage>
                </div>
              }
            </div>
          </Modal.Body>
        </Modal>


        <Modal show={showA7} onHide={handleCloseA7} className="modalA4 modalA_Template2">
          <Modal.Body>
            <div>
              {seccionAmodalDivided[6] === 'si' ?
                <div className="display_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA7} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA7() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      <div className="container_sub_richcontext">
                        {documentToReactComponents(seccionAmodal7.json, optionsModal7)}
                      </div>
                      <div className="container_img_modal">
                        {ImageModal7 ?
                          <img src={ImageModal7.fields.file["en-US"].url} alt={tItulo} /> : null
                        }
                      </div>
                    </div>
                  </BackgroundImage>
                </div>
                :
                <div className="single_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA7} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA7() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      {documentToReactComponents(seccionAmodal7.json, optionsModal7)}
                    </div>
                  </BackgroundImage>
                </div>
              }
            </div>
          </Modal.Body>
        </Modal>


        <Modal show={showA8} onHide={handleCloseA8} className="modalA4 modalA_Template2">
          <Modal.Body>
            <div>
              {seccionAmodalDivided[7] === 'si' ?
                <div className="display_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA8} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA8() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      <div className="container_sub_richcontext">
                        {documentToReactComponents(seccionAmodal8.json, optionsModal8)}
                      </div>
                      <div className="container_img_modal">
                        {ImageModal7 ?
                          <img src={ImageModal7.fields.file["en-US"].url} alt={tItulo} /> : null
                        }
                      </div>
                    </div>
                  </BackgroundImage>
                </div>
                :
                <div className="single_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA8} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA8() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      {documentToReactComponents(seccionAmodal8.json, optionsModal8)}
                    </div>
                  </BackgroundImage>
                </div>
              }
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showA9} onHide={handleCloseA9} className="modalA4 modalA_Template2">
          <Modal.Body>
            <div>
              {seccionAmodalDivided[8] === 'si' ?
                <div className="display_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA9} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA9() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      <div className="container_sub_richcontext">
                        {documentToReactComponents(seccionAmodal9.json, optionsModal9)}
                      </div>
                      <div className="container_img_modal">
                        {ImageModal7 ?
                          <img src={ImageModal7.fields.file["en-US"].url} alt={tItulo} /> : null
                        }
                      </div>
                    </div>
                  </BackgroundImage>
                </div>
                :
                <div className="single_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA9} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA9() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      {documentToReactComponents(seccionAmodal9.json, optionsModal9)}
                    </div>
                  </BackgroundImage>
                </div>
              }
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showA10} onHide={handleCloseA10} className="modalA4 modalA_Template2">
          <Modal.Body>
            <div>
              {seccionAmodalDivided[9] === 'si' ?
                <div className="display_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" role="button" onClick={handleCloseA10} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA10() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      <div className="container_sub_richcontext">
                        {documentToReactComponents(seccionAmodal10.json, optionsModal10)}
                      </div>
                      <div className="container_img_modal">
                        {ImageModal7 ?
                          <img src={ImageModal7.fields.file["en-US"].url} alt={tItulo} /> : null
                        }
                      </div>
                    </div>
                  </BackgroundImage>
                </div>
                :
                <div className="single_modal">
                  <BackgroundImage fluid={backgroundsImage[5].fluid}>
                    <div className="close_button_container" onClick={handleCloseA10} role="button" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleCloseA10() } }} tabIndex="0">
                      <Image fluid={iconCloseModal.fluid} />
                    </div>
                    <div className="container_richcontext">
                      {documentToReactComponents(seccionAmodal10.json, optionsModal10)}
                    </div>
                  </BackgroundImage>
                </div>
              }
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  )
}

export default TemplateChabucaTemplate

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}